import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonApp } from '../AppComponents/ButtonApp'
import { supabase } from '../../lib/api'
import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles } from '@mui/material/styles'

export function ModalAnularInscripcion({
    open,
    handleClose,
    arrayText,
    planificacion,
    language,
    fetchTallerByUser,
}) {
    const navigate = useNavigate()

    const stateUser = [
        {
            value: '0',
            labelCat: 'Inscrit',
            labelCas: 'Inscrito',
        },
        {
            value: '1',
            labelCat: 'En espera',
            labelCas: 'En espera',
        },
        {
            value: '2',
            labelCat: 'Baixa',
            labelCas: 'Baja',
        },
    ]

    const [state, setState] = useState('2')
    const [dataBaixa, setDataBaixa] = useState(new Date().toISOString().substring(0, 10))
    const [motiuBaixa, setMotiuBaixa] = useState('')

    useEffect(() => {
        setState(planificacion?.usuario_planificacion[0]?.estado)
        setDataBaixa(new Date().toISOString().substring(0, 10))
        setMotiuBaixa(planificacion?.usuario_planificacion[0]?.motiu_no_assistencia)
    }, [planificacion])

    useEffect(() => {
        if (open === true) return
        setState('2')
        setDataBaixa(new Date().toISOString().substring(0, 10))
        setMotiuBaixa('')
    }, [open])

    const save = async (event) => {
        event.preventDefault()
        let id = planificacion?.usuario_planificacion[0]?.id
        const { error } = await supabase
            .from('usuario_planificacion')
            .update({
                estado: 2,
                fecha_baja: dataBaixa,
                motiu_no_assistencia: motiuBaixa,
            })
            .eq('id', id)

        if (error === null) {
            fetchTallerByUser()
            handleClose()
            return
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
                <DialogContent>
                    <DialogTitle
                        fontSize={25}
                        display="flex"
                        justifyContent={'space-between'}
                    >
                        <span> {arrayText[0]}</span>
                        <span
                            onClick={handleClose}
                            children="x"
                            style={{
                                cursor: 'pointer',
                                fontSize: 40,
                            }}
                        />
                    </DialogTitle>
                    <hr />
                    <form onSubmit={save}>
                        <div className="row">
                            <div className="col-md-1">
                                <p className="text-before-input">{arrayText[2]}</p>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        id="nif-registed"
                                        className="form-control"
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        value={
                                            language === 'castellano'
                                                ? planificacion?.talleres?.nom_taller_cas
                                                : planificacion?.talleres?.nom_taller_cat
                                        }
                                        onChange={(e) => ''}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1">
                                <p className="text-before-input">{arrayText[3]}</p>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group mb-3">
                                    <select
                                        class="form-select"
                                        aria-label="Default select example"
                                        value={state}
                                    >
                                        <option value={state}>
                                            {language === 'castellano'
                                                ? stateUser[2]['labelCas']
                                                : stateUser[2]['labelCat']}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1">
                                <p className="text-before-input">{arrayText[4]}</p>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group mb-3">
                                    <input
                                        type="date"
                                        id="nif-registed"
                                        className="form-control"
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        value={dataBaixa}
                                        readOnly
                                        onChange={(e) => setDataBaixa(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1">
                                <p className="text-before-input">{arrayText[5]}</p>
                            </div>
                            <div className="col-md-11">
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        required
                                        onInvalid={() => console.log('working!')}
                                        className="form-control"
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        value={motiuBaixa}
                                        onChange={(e) => setMotiuBaixa(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="col-md-2">
                                <ButtonApp type="submit" label={'Guardar'} />
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
