import React from 'react'
import { ButtonApp } from '../AppComponents/ButtonApp'
import { ModalLoginAreaPersonal } from '../AppComponents/ModalLogInAreaPersonal'

const arrayText = [
    {
        castellano:
            'Desde la ficha de inscripción de cualquier taller podéis acceder a vuestra área personal ',
        catalan:
            'Des de la fitxa d"inscripció de qualsevol taller podeu accedir a la vostra àrea personal',
    },
    {
        castellano: 'Área personal:',
        catalan: 'Àrea personal:',
    },
    {
        castellano: 'Contraseña',
        catalan: 'Contrasenya:',
    },
    {
        castellano: 'Entrar',
        catalan: 'Entrar',
    },
]

export function ButtonAPersonal({ language }) {
    const [open, setOpen] = React.useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <ModalLoginAreaPersonal
                open={open}
                handleClose={handleClose}
                language={language}
                arrayText={
                    language === 'castellano'
                        ? [
                              arrayText[0]['castellano'],
                              arrayText[1]['castellano'],
                              arrayText[2]['castellano'],
                              arrayText[3]['castellano'],
                          ]
                        : [
                              arrayText[0]['catalan'],
                              arrayText[1]['catalan'],
                              arrayText[2]['catalan'],
                              arrayText[3]['catalan'],
                          ]
                }
            />
            <ButtonApp
                method={handleClickOpen}
                label={language === 'castellano' ? 'Mis talleres' : 'Els meus tallers'}
                addStyle={{ marginTop: 10 }}
            />
        </>
    )
}
