import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import App from './App'
import { AreaPersonal } from './components/AreaPersonal/AreaPersonal'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/planificacion/:id" element={<App />} />
                <Route path="/planificacio/:id" element={<App />} />
                <Route path="/areaPersonal/:id" element={<AreaPersonal />} />
            </Routes>
        </Router>
    </React.StrictMode>
)
