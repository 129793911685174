import { useParams, useLocation } from 'react-router-dom'
import { AppBar } from './components/AppBar'
import { useState, useEffect } from 'react'
import { Main } from './components/Main'
import { FooterCast } from './components/Castellano/FooterCast'
import { FooterCat } from './components/Catalan/FooterCat'
import { FormRegisteredUserCast } from './components/Castellano/FormRegisteredUserCast'
import { FormRegisteredUserCat } from './components/Catalan/FormRegisteredUserCat'
import { FormUnRegisteredUserCast } from './components/Castellano/FormUnRegisteredUserCast'
import { FormUnRegisteredUserCat } from './components/Catalan/FormUnRegisteredUserCat'
import { supabase } from './lib/api'

function App() {
    const location = useLocation()
    const [language, setLanguage] = useState(location.pathname.split('/')[1] === 'planificacio' ? 'catalan' : 'castellano')

    const { id } = useParams()
    const [taller, setTaller] = useState(null)

    useEffect(() => {
        findTaller()
    }, [])

    const findTaller = async () => {
        const { data, error } = await supabase
            .from('planificacion_talleres')
            .select(
                'data_taller, nombre_usuaris_maxim, estat, hora_i, hora_f, talleres(id_taller, codi_taller, nom_taller_cat, nom_taller_cas)'
            )
            .eq('id_planificacio', id)
            .maybeSingle()

        if (error) {
            console.log(error.message)
            return
        }

        let { hora_i, hora_f } = data

        let splitHora_i = hora_i.split(':')
        hora_i = `${splitHora_i[0]}:${splitHora_i[1]}`
        let splitHora_f = hora_f.split(':')
        hora_f = `${splitHora_f[0]}:${splitHora_f[1]}`

        setTaller({
            ...data,
            hora_i: hora_i,
            hora_f: hora_f,
        })
    }
    return (
        <>
            <div className="container-md">
                <AppBar language={language} setLanguage={setLanguage} logged={false} />
                <Main language={language} taller={taller} />
                {language === 'castellano' || language === 'castellà' ? (
                    <FormRegisteredUserCast taller={taller} />
                ) : (
                    <FormRegisteredUserCat taller={taller} />
                )}
                {language === 'castellano' || language === 'castellà' ? (
                    <FormUnRegisteredUserCast taller={taller} />
                ) : (
                    <FormUnRegisteredUserCat taller={taller} />
                )}
                {language === 'castellano' || language === 'castellà' ? <FooterCast /> : <FooterCat />}
            </div>
        </>
    )
}

export default App
