import * as React from 'react'
import { ButtonApp } from '../AppComponents/ButtonApp'
import { supabase } from '../../lib/api'
import { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export function ModalRememberPassword({ arrayText }) {
    const [open, setOpen] = React.useState(false)
    const [dni, setDni] = useState('')
    const [password, setPassword] = useState('')
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setDni('')
        setPassword('')
    }

    const showPasswordByDni = async () => {
        let { data, error } = await supabase.from('usuarios').select('*').eq('dni', dni).maybeSingle()

        if (error) {
            console.log(error)
            return false
        }

        setPassword(data['password'])
    }

    return (
        <div>
            <ButtonApp method={handleClickOpen} label={arrayText[0]} />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{arrayText[1]}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{arrayText[2]}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={arrayText[7]}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={dni}
                        onChange={(e) => setDni(e.target.value)}
                    />
                    {password !== '' ? (
                        <>
                            <DialogTitle>{arrayText[3]}</DialogTitle>
                            <TextField
                                autoFocus
                                margin="dense"
                                label={arrayText[4]}
                                type="text"
                                fullWidth
                                variant="standard"
                                value={password}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    {password !== '' ? (
                        <>
                            <Button onClick={handleClose}>{arrayText[5]}</Button>
                        </>
                    ) : (
                        <>
                            <Button onClick={handleClose}>{arrayText[5]}</Button>
                            <Button onClick={showPasswordByDni}>{arrayText[6]}</Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}
