import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '../App.css'
import { MainCast } from './Castellano/MainCast'
import { MainCat } from './Catalan/MainCat'
import { supabase } from '../lib/api'

export function Main({ language, taller }) {
    return (
        <>
            {taller !== null ? (
                language === 'castellano' || language === 'castellà' ? (
                    <MainCast taller={taller} />
                ) : (
                    <MainCat taller={taller} />
                )
            ) : (
                ''
            )}
        </>
    )
}
