import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonApp } from '../AppComponents/ButtonApp'
import { supabase } from '../../lib/api'
import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export function ModalLoginAreaPersonal({ open, handleClose, language, arrayText }) {
    const navigate = useNavigate()
    const [dni, setDni] = useState('')
    const [password, setPassword] = useState('')

    const login = async () => {
        let { data, error } = await supabase
            .from('usuarios')
            .select('*')
            .match({ dni: dni, password: password })
            .maybeSingle()

        if (error) {
            console.log(error)
            return false
        }

        navigate(`/areaPersonal/${data['id_usuario']}`, {
            state: {
                language,
            },
        })
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
                <DialogContent>
                    <DialogContentText>{arrayText[0]}</DialogContentText>
                    <DialogTitle fontSize={25}>{arrayText[1]}</DialogTitle>
                    <div className="row">
                        <div className="col-md-1">
                            <p className="text-before-input">NIF:</p>
                        </div>
                        <div className="col-md-3">
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    id="nif-registed"
                                    className="form-control"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    value={dni}
                                    onChange={(e) => setDni(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <p className="text-before-input">{arrayText[2]}</p>
                        </div>
                        <div className="col-md-3">
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    id="contrasenya-registed"
                                    className="form-control"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <ButtonApp method={login} label={arrayText[3]} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
