import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { AppBar } from '../AppBar'
import { Header } from './Header'
import { Body } from './Body'
import { Table } from './Table'
import { FooterCat } from '../Catalan/FooterCat'
import { FooterCast } from '../Castellano/FooterCast'

export function AreaPersonal() {
    const location = useLocation()
    const { id } = useParams()
    const [language, setLanguage] = useState('castellano')

    useEffect(() => {
        setLanguage(location.state.language)
    }, [location.state.language])

    return (
        <>
            <div className="container-md">
                <AppBar language={language} setLanguage={setLanguage} logged={true} />
                <Header language={language} />
                <Body language={language} />
                <Table language={language} userId={id} />
                {language === 'castellano' ? <FooterCast /> : <FooterCat />}
            </div>
        </>
    )
}
