import React from 'react'

export function DisplayErrors({ errorPassword, errorDniRepeated, errorInsertUser, errorInsertUserPlan }) {
    return (
        <>
            {errorPassword !== null || errorDniRepeated !== null ? (
                <div class="alert alert-secondary" role="alert">
                    <ul>
                        {errorPassword !== null ? (
                            <li
                                style={{
                                    paddingTop: 10,
                                    color: errorPassword.type === 'error' ? 'red' : 'green',
                                }}
                            >
                                {errorPassword.value}
                            </li>
                        ) : (
                            ''
                        )}
                        {errorDniRepeated !== null ? (
                            <li
                                style={{
                                    paddingTop: 10,
                                    color: errorDniRepeated.type === 'error' ? 'red' : 'green',
                                }}
                            >
                                {errorDniRepeated.value}
                            </li>
                        ) : (
                            ''
                        )}
                    </ul>
                </div>
            ) : (
                ''
            )}
            {errorInsertUser !== null || errorInsertUserPlan !== null ? (
                <div class="alert alert-secondary" role="alert">
                    <ul>
                        {errorInsertUser !== null ? (
                            <li
                                style={{
                                    paddingTop: 10,
                                    color: errorInsertUser.type === 'error' ? 'red' : 'green',
                                }}
                            >
                                {errorInsertUser.value}
                            </li>
                        ) : (
                            ''
                        )}
                        {errorInsertUserPlan !== null ? (
                            <li
                                style={{
                                    paddingTop: 10,
                                    color: errorInsertUserPlan.type === 'error' ? 'red' : 'green',
                                }}
                            >
                                {errorInsertUserPlan.value}
                            </li>
                        ) : (
                            ''
                        )}
                    </ul>
                </div>
            ) : (
                ''
            )}
        </>
    )
}
