import React, { useEffect, useState } from 'react'
import { supabase } from '../../lib/api'
import { Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { ModalAnularInscripcion } from '../AppComponents/ModalAnularInscripcion'

export function Table({ language, userId }) {
    const [talleres, setTalleres] = useState([])
    const [open, setOpen] = useState(false)
    const [planificacion, setPlanificacion] = useState(null)
    const STATES = {
        0: 'Inscrito',
        1: `En espera`,
        2: 'Baja',
    }

    const STATESD = {
        0: 'Inscrit',
        1: `En espera`,
        2: 'Baixa',
    }

    const arrayTextCat = [
        "Anul·lació d'inscripció",
        'Codi:',
        'Nom:',
        'Estat usuari:',
        'Data baixa:',
        'Motiu baixa:',
    ]
    const arrayTextCas = [
        'Anulación de la inscripción',
        'Código:',
        'Nombre:',
        'Estado usuario:',
        'Fecha baja:',
        'Motivo baja:',
    ]

    useEffect(() => {
        fetchTallerByUser()
    }, [])

    const fetchTallerByUser = async () => {
        let { data, error } = await supabase
            .from('usuario_planificacion')
            .select('planificacion_talleres(id_planificacio, data_taller_text), estado')
            .eq('usuari_id', userId)

        if (error) {
            console.log(error)
            return false
        }

        fetchAllTalleresByIdTaller(data)
    }

    const fetchAllTalleresByIdTaller = async (arrayIdTalleres) => {
        let arrayIdTaller = []

        arrayIdTalleres.forEach((item, index) => {
            arrayIdTaller.push(item['planificacion_talleres']['id_planificacio'])
        })

        let { data, error } = await supabase
            .from('planificacion_talleres')
            .select(
                'id_planificacio, data_taller_text, estat, hora_i, hora_f, talleres(nom_taller_cat, nom_taller_cas, codi_taller), usuario_planificacion(estado, fecha_baja, motiu_no_assistencia, id)'
            )
            .filter('id_planificacio', 'in', '(' + arrayIdTaller + ')')
            .eq('usuario_planificacion.usuari_id', userId)
            .order('data_taller_text', { ascending: false })

        if (error) {
            console.log(error)
            return false
        }
        setTalleres(data)
    }

    const edit = (planificacion) => {
        console.log(planificacion)
        handleClick()
        setPlanificacion(planificacion)
    }

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            {planificacion !== null ? (
                <ModalAnularInscripcion
                    open={open}
                    handleClose={handleClick}
                    planificacion={planificacion}
                    language={language}
                    arrayText={language === 'castellano' ? arrayTextCas : arrayTextCat}
                    fetchTallerByUser={fetchTallerByUser}
                />
            ) : null}
            <table class="table">
                <thead>
                    <tr>
                        {language === 'castellano' ? (
                            <>
                                <th scope="col">Nombre taller</th>
                                <th scope="col">Fecha del taller</th>
                                <th scope="col">Hora inicio</th>
                                <th scope="col">Hora fin</th>
                                <th scope="col">Estado usuario</th>
                                <th scope="col">Anular inscripción</th>
                            </>
                        ) : (
                            <>
                                <th scope="col">Nom taller</th>
                                <th scope="col">Data del taller</th>
                                <th scope="col">Hora inici</th>
                                <th scope="col">Hora fi</th>
                                <th scope="col">Estat usuari</th>
                                <th scope="col">Anul·lar inscripció</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {talleres?.map((item) => (
                        <tr>
                            <th scope="row">
                                {
                                    item['talleres'][
                                        `${
                                            language === 'castellano'
                                                ? 'nom_taller_cas'
                                                : 'nom_taller_cat'
                                        }`
                                    ]
                                }
                            </th>
                            <td>
                                {item['data_taller_text'].split('-').reverse().join('-')}
                            </td>
                            <td>{item['hora_i']}</td>
                            <td>{item['hora_f']}</td>
                            <td>
                                {language === 'castellano'
                                    ? STATES[item?.usuario_planificacion[0]?.estado]
                                    : STATESD[item?.usuario_planificacion[0]?.estado]}
                            </td>
                            <td>
                                <Tooltip
                                    arrow={'up'}
                                    title={
                                        language === 'castellano'
                                            ? 'Anular inscripción'
                                            : 'Anul·lar inscripció'
                                    }
                                >
                                    <IconButton onClick={() => edit(item)}>
                                        <EditIcon htmlColor="#388e3c" />
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
