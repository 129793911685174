import React from 'react'

const buttonStyles = {
    backgroundColor: '#ffffff',
    color: '#616161',
    borderRadius: 30,
    border: '1px solid #dee2e6',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 30,
    paddingRight: 30,
}

export function ButtonApp({
    method = '',
    label,
    addStyle,
    type = 'button',
    className = '',
}) {
    return (
        <button
            type={type}
            onClick={method}
            style={{
                ...buttonStyles,
                ...addStyle,
            }}
            className="button-anular-inscripcion"
        >
            {label}
        </button>
    )
}
