import React from 'react'

export function Body({ language }) {
    const arrayText = [
        {
            castellano: 'Usuario/a registrado/a',
            catalan: 'Usuari/a registrat/da:',
        },
        {
            castellano: 'Colocando el ratón encima del código se ve el nombre del taller',
            catalan: ' Col·locant el ratolí a sobre del codi es veu el nom del taller',
        },
        {
            castellano:
                'Colocando el ratón encima del estado del taller en un taller anulado se ve el motivo de la anulación',
            catalan:
                "Col·locant el ratolí a sobre de l'estat del taller en un taller anul·lat es veu el motiu de l'anul·lació",
        },
        {
            castellano:
                'Colocando el ratón encima de la fecha de baja se ve el motivo de la no asistencia',
            catalan:
                'Col·locant el ratolí a sobre de la data de baixa es veu el motiu de la no assistència',
        },
    ]
    return (
        <>
            <div className="row pt-4">
                <div className="col-md-12">
                    <h5 id="taller-title3">{arrayText[0][language]}</h5>
                </div>
            </div>
            <hr className="divider2 mb-2" />
            {/*
                    <p id="info-user-registrat" className="pb-4">
                <ul id="ul-recorda-list">
                    <li>{arrayText[1][language]}</li>
                    <li>{arrayText[2][language]}</li>
                    <li>{arrayText[3][language]}</li>
                </ul>
            </p>
                */}
        </>
    )
}
