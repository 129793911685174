import React, { useState, useEffect, useRef } from 'react'
import { supabase } from '../../lib/api'
import { useParams } from 'react-router-dom'
import { DisplayErrorsRegisteredUsers } from '../DisplayErrorsRegisteredUsers'
import { DisplaySuccessMessage } from '../DisplaySuccessMessage'
import { DisplayErrorAsistenciaRegisteredUsers } from '../DisplayErrorAsistenciaRegisteredUsers'
import { ModalRememberPassword } from '../AppComponents/ModalRememberPassword'

const STATES = {
    0: 'Inscripción',
    1: 'Lista de espera',
    2: 'Cerrado',
    3: 'Anulado',
    '-99': 'Proximamente',
}

const arrayTextModal = [
    'Recordar contraseña',
    'RECORDAR CONTRASEÑA',
    'Para saber tu contraseña, debes introducir tu DNI.',
    'Tu contraseña es:',
    'Contraseña',
    'Cerrar',
    'Enviar',
    'DNI',
]

export function FormRegisteredUserCast({ taller }) {
    const { id } = useParams()
    const [nif, setNif] = useState('')
    const [password, setPassword] = useState('')
    const [disabled, setDisabled] = useState(false)
    const clickCountRef = useRef(0)
    const [labelButton, setLabelButton] = useState('Inscripción')
    const [user, setUser] = useState(null)
    const [tallerObj, setTallerObj] = useState(null)

    const [userExistsError, setUserExistsError] = useState(null)
    const [userSamePlanification, setUserSamePlanification] = useState(null)
    const [userMaxTallerCurrentMonth, setUserMaxTallerCurrentMonth] = useState(null)
    const [userMaxTallerCuatrimestre, setUserMaxTallerCuatrimestre] = useState(null)
    const [errorInsertUserPlan, setErrorInsertUserPlan] = useState(null)
    const [errorAsistencia, setErrorAsistencia] = useState(null)

    useEffect(() => {
        if (taller !== null) {
            checkTallerState()
            setTallerObj(taller)
        }
    }, [taller])

    useEffect(() => {
        if (errorInsertUserPlan !== null) {
            setUserExistsError(null)
            setUserSamePlanification(null)
            setUserMaxTallerCurrentMonth(null)
            setUserMaxTallerCuatrimestre(null)
        }
    }, [errorInsertUserPlan])

    useEffect(() => {
        if (
            userExistsError !== null ||
            userSamePlanification !== null ||
            userMaxTallerCurrentMonth !== null ||
            userMaxTallerCuatrimestre !== null
        ) {
            setErrorInsertUserPlan(null)
        }
    }, [
        userExistsError,
        userSamePlanification,
        userMaxTallerCurrentMonth,
        userMaxTallerCuatrimestre,
    ])

    const checkTallerState = () => {
        let { estat } = taller

        setDisabled(estat !== 0 && estat !== 1 ? true : false)

        setLabelButton(STATES[estat])
    }

    const fetchUsers = async (nif, password) => {
        let { data, error } = await supabase
            .from('usuarios')
            .select('*')
            .eq('dni', nif)
            .eq('password', password)
            .maybeSingle()

        if (error) {
            console.log(error)
            return false
        }

        if (data === null) {
            setUserExistsError({
                type: 'error',
                value: 'El usuario o la contraseña no existen',
            })
            setUserSamePlanification(null)
            setUserMaxTallerCurrentMonth(null)
            return false
        }

        setUserExistsError(null)
        setUser(data)
        return data
    }

    const countPlanificationSameUser = async (userId) => {
        let { count, error } = await supabase
            .from('usuario_planificacion')
            .select('id', { count: 'exact' })
            .eq('usuari_id', userId)
            .eq('planificacion_id', id)
            .maybeSingle()

        if (error) {
            console.log(error)
            return 99
        }

        if (count === null) {
            return 0
        }

        return count
    }

    const getDays = (year, month) => {
        return new Date(year, month, 0).getDate()
    }

    const getCurrentCuatrimestre = (currentYear, currentMonth) => {
        let startDate = ''
        let endDate = ''
        if (currentMonth >= 1 && currentMonth <= 4) {
            startDate = currentYear + '-01-01'
            endDate = currentYear + '-04-30'
        }

        if (currentMonth >= 5 && currentMonth <= 8) {
            startDate = currentYear + '-05-01'
            endDate = currentYear + '-08-31'
        }

        if (currentMonth >= 9 && currentMonth <= 12) {
            startDate = currentYear + '-09-01'
            endDate = currentYear + '-12-31'
        }

        return { startDate, endDate }
    }

    const countTalleresCurrentMonthByUser = async (userId) => {
        let currentYear = new Date().getFullYear()
        let currentMonth =
            new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
        let days = getDays(currentYear, currentMonth)

        let { data, error } = await supabase
            .from('usuario_planificacion')
            .select('*')
            .eq('usuari_id', userId)
            .gte('fecha_inscripcion', `${currentYear}/${currentMonth}/01`)
            .lte('fecha_inscripcion', `${currentYear}/${currentMonth}/${days}`) // less or equal than
        //greater or equal than
        if (error) {
            console.log(error)
            return
        }

        let count = 0

        data.forEach((data) => {
            if (data.planificacion_talleres === null) {
                return
            }

            count = count + 1
        })

        return count
    }

    const countQuantityTaller = async (planifications) => {
        let array = []
        const { id_taller } = tallerObj.talleres

        planifications.forEach((plan) => {
            array.push(plan.planificacion_talleres.taller_id)
        })

        let tallerDuplicadoActual = array.filter((item, index) => item === id_taller)

        if (tallerDuplicadoActual.length === 2) {
            //talleres repetidos
            setUserMaxTallerCuatrimestre({
                type: 'error',
                value: 'No puedes inscribirte más de dos veces a un mismo taller en este cuatrimestre',
            })

            return false //No puede inscribirse
        }
        setUserMaxTallerCuatrimestre(null)
        return true
    }

    const countMaxTallerQuatrimestre = async (userId) => {
        let currentYear = new Date().getFullYear()
        let currentMonth =
            new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
        let { startDate, endDate } = getCurrentCuatrimestre(currentYear, currentMonth)

        let { data, error } = await supabase
            .from('usuario_planificacion')
            .select('planificacion_talleres(taller_id)')
            .eq('usuari_id', userId)
            .lte('planificacion_talleres.data_taller_text', `${endDate}`) // less or equal than
            .gte('planificacion_talleres.data_taller_text', `${startDate}`) //greater or equal than

        if (error) {
            console.log(error)
            return
        }

        if (data.length === 0) {
            return
        }
        let deleteNullValues = data.filter((item) => item.planificacion_talleres !== null)

        let checkBool = await countQuantityTaller(deleteNullValues)

        return checkBool
    }

    const changeTallerState = async () => {
        let { data, error } = await supabase
            .from('usuario_planificacion')
            .select('usuari_id, planificacion_talleres(id_planificacio)')
            .eq('planificacion_id', id)

        let { nombre_usuaris_maxim } = taller

        if (error !== null) {
            console.log(error.message)
            return
        }

        if (nombre_usuaris_maxim > data.length) {
            return
        }
        await supabase
            .from('planificacion_talleres')
            .update({ estat: 1 })
            .eq('id_planificacio', id)
    }

    const getLastRecordInserted = async () => {
        let { data, error } = await supabase
            .from('usuario_planificacion')
            .select('id')
            .order('id', { ascending: false })
            .limit(1)
            .single()

        if (error) {
            console.log(error.message)
            return { id: 0 }
        }

        if (data === null) {
            return { id: 1 }
        }

        return data
    }

    const subscribeUser = async (USERID) => {
        clickCountRef.current += 1

        if (clickCountRef.current >= 1) {
            setDisabled(true)
        }

        if (clickCountRef.current >= 2) return

        let data = await getLastRecordInserted()
        let { estat } = taller
        let currentYear = new Date().getFullYear()
        let currentMonth =
            new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
        let date = `${currentYear}-${currentMonth}-${new Date().getDate()}`

        let { error } = await supabase.from('usuario_planificacion').insert({
            id: data.id + 1,
            usuari_id: USERID,
            planificacion_id: id,
            fecha_inscripcion: date,
            estado: estat,
            asistencia_id: 0,
        })

        if (error) {
            console.log(error)
            return false
        }

        setErrorInsertUserPlan({
            type: 'success',
            value:
                estat === 0
                    ? 'Inscrito correctamente!'
                    : 'Apuntando a la lista de espera',
        })

        return true
    }

    const checkAsistencia = async (userId) => {
        let currentYear = new Date().getFullYear()
        let currentMonth =
            new Date().getMonth() + 1 < 10
                ? parseInt(`0${new Date().getMonth() + 1}`)
                : parseInt(new Date().getMonth() + 1)
        let currentDay = new Date().getDate()

        if (currentMonth === 1) {
            currentMonth = 12
            currentYear = currentYear - 1
        } else {
            currentMonth = currentMonth - 1
        }

        currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth

        console.log(currentMonth)

        let days = getDays(currentYear, currentMonth)

        if (currentDay < 15 || currentDay > 25) {
            return true //No existe penalizacion
        }

        let { data, error } = await supabase
            .from('usuario_planificacion')
            .select('planificacion_talleres(data_taller_text)')
            .eq('usuari_id', userId)
            .eq('asistencia_id', 3)
            .lte(
                'planificacion_talleres.data_taller_text',
                `${currentYear}-${currentMonth}-${days}`
            ) // less or equal than
            .gte(
                'planificacion_talleres.data_taller_text',
                `${currentYear}-${currentMonth}-01`
            ) //greater or equal than

        if (error) {
            console.log(error.message)
            return
        }

        let arrayWithoutNullValues = data.filter(
            (item) => item.planificacion_talleres !== null
        ).length

        if (arrayWithoutNullValues !== 0) {
            return false // Existe penalizacion
        }

        return true //No existe penalizacion
    }

    const onSubmit = async () => {
        if (nif === '' || password === '') return

        let response = await fetchUsers(nif, password) //encontrar usuario con nif y contraseña
        if (response === false) {
            return
        }
        let asistencia = await checkAsistencia(response.id_usuario)
        if (asistencia === false) {
            setErrorAsistencia({
                type: 'error',
                value: 'NO PUEDES INSCRIBIRTE ENTRE LOS DÍAS 15 Y 25 DE ESTE MES A NINGÚN TALLER, POR HABER FALTADO EN EL MES ANTERIOR A UNO O MÁS TALLERES SIN JUSTIFICAR',
            })

            return
        }

        setErrorAsistencia(null)
        /////
        let count = await countPlanificationSameUser(response.id_usuario)

        if (count === 1 || count === 99) {
            setUserSamePlanification({
                type: 'error',
                value: 'Ya te has inscrito 1 vez a este taller',
            })
            setUserExistsError(null)
            return
        }
        setUserExistsError(null)
        setUserSamePlanification(null)

        let countTalleresSameMonth = await countTalleresCurrentMonthByUser(
            response.id_usuario
        )

        if (countTalleresSameMonth === 8) {
            setUserMaxTallerCurrentMonth({
                type: 'error',
                value: 'Ya estás inscrito en 8 talleres',
            })
            return
        }

        setUserMaxTallerCurrentMonth(null)

        let checkBool = await countMaxTallerQuatrimestre(response.id_usuario)

        if (checkBool === false) {
            return
        }

        let { estat } = taller

        if (estat !== 0 && estat !== 1) {
            return
        }
        //subscribe

        let boolSubscribe = await subscribeUser(response.id_usuario)

        if (boolSubscribe === false) {
            setErrorInsertUserPlan(null)
            return
        }

        changeTallerState()
    }

    return (
        <>
            <div className="row pt-4">
                <div className="col-md-12">
                    <h5 id="taller-title3">Usuario registrado:</h5>
                </div>
            </div>
            <hr className="divider2 mb-5" />
            <p id="info-user-registrat" className="pb-4">
                Si ya te has registrado, identifícate y dale clic al botón para
                inscribirte al <strong>TALLER</strong>.
            </p>
            <div className="row">
                <div className="col-md-1">
                    <p className="text-before-input">NIF:</p>
                </div>
                <div className="col-md-3">
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            id="nif-registed"
                            className="form-control"
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={nif}
                            onChange={(e) => setNif(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-1 offset-md-1">
                    <p className="text-before-input">Contraseña:</p>
                </div>
                <div className="col-md-3">
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            id="contrasenya-registed"
                            className="form-control"
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <button
                        id="inscripcion-user-registrat-button"
                        type="button"
                        className="btn btn-primary"
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        {labelButton}
                    </button>
                    <p></p>
                    <ModalRememberPassword arrayText={arrayTextModal} />
                </div>
                <DisplayErrorsRegisteredUsers
                    userExistsError={userExistsError}
                    userSamePlanification={userSamePlanification}
                    userMaxTallerCurrentMonth={userMaxTallerCurrentMonth}
                    userMaxTallerCuatrimestre={userMaxTallerCuatrimestre}
                />
                <DisplaySuccessMessage errorInsertUserPlan={errorInsertUserPlan} />
                <DisplayErrorAsistenciaRegisteredUsers
                    errorAsistencia={errorAsistencia}
                />
            </div>
        </>
    )
}
