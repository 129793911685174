import React from 'react'

export function Header({ language }) {
    return (
        <>
            {language === 'castellano' ? (
                <>
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 id="taller-title2">MIS TALLERES</h3>
                        </div>
                    </div>
                    <p id="recorda" style={{ marginTop: 50 }}>
                        Recuerda que:
                    </p>
                    <div id="recorda-list">
                        <ul id="ul-recorda-list">
                            <li>
                                Una persona no puede estar inscrita en más de 8 talleres
                                en un mismo mes.
                            </li>
                            <li>
                                Una persona no puede realizar más de dos veces un mismo
                                taller durante el cuatrimestre.
                            </li>
                            <li>
                                No presentarse a un taller sin justificar, supondrá la
                                pérdida del derecho de inscripción durante los 10 primeros
                                días del siguiente periodo de apertura a nuevas
                                actividades.
                            </li>
                        </ul>
                    </div>
                </>
            ) : (
                <>
                    <div className="row pt-5">
                        <div className="col-md-12">
                            <h3 id="taller-title2">ELS MEUS TALLERS</h3>
                        </div>
                    </div>
                    <p id="recorda" style={{ marginTop: 50 }}>
                        Recorda que:
                    </p>
                    <div id="recorda-list">
                        <ul id="ul-recorda-list">
                            <li>
                                Una persona no es pot inscriure a més de 8 tallers en un
                                mateix mes.
                            </li>
                            <li>
                                Una persona no pot fer més de 2 cops un mateix taller
                                durant el quatrimestre.
                            </li>
                            <li>
                                No presentar-se a un taller sense justificar suposa la
                                pèrdua del dret d'inscripció durant els primers 10 dies
                                del següent període d'obertura a noves activitats.
                            </li>
                        </ul>
                    </div>
                </>
            )}
        </>
    )
}
