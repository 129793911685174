import React from 'react'

export function DisplayErrorsRegisteredUsers({
    userExistsError,
    userSamePlanification,
    userMaxTallerCurrentMonth,
    userMaxTallerCuatrimestre,
}) {
    return (
        <>
            {userExistsError !== null ||
            userSamePlanification !== null ||
            userMaxTallerCurrentMonth !== null ||
            userMaxTallerCuatrimestre !== null ? (
                <div class="alert alert-secondary" role="alert">
                    <ul>
                        {userExistsError !== null ? (
                            <li
                                style={{
                                    paddingTop: 10,
                                    color: userExistsError.type === 'error' ? 'red' : '#66bb6a',
                                    fontWeight: 'bold',
                                }}
                            >
                                {userExistsError.value}
                            </li>
                        ) : (
                            ''
                        )}
                        {userSamePlanification !== null ? (
                            <li
                                style={{
                                    paddingTop: 10,
                                    color: userSamePlanification.type === 'error' ? 'red' : '#66bb6a',
                                    fontWeight: 'bold',
                                }}
                            >
                                {userSamePlanification.value}
                            </li>
                        ) : (
                            ''
                        )}
                        {userMaxTallerCurrentMonth !== null ? (
                            <li
                                style={{
                                    paddingTop: 10,
                                    color: userMaxTallerCurrentMonth.type === 'error' ? 'red' : '#66bb6a',
                                    fontWeight: 'bold',
                                }}
                            >
                                {userMaxTallerCurrentMonth.value}
                            </li>
                        ) : (
                            ''
                        )}

                        {userMaxTallerCuatrimestre !== null ? (
                            <li
                                style={{
                                    paddingTop: 10,
                                    color: userMaxTallerCuatrimestre.type === 'error' ? 'red' : '#66bb6a',
                                    fontWeight: 'bold',
                                }}
                            >
                                {userMaxTallerCuatrimestre.value}
                            </li>
                        ) : (
                            ''
                        )}
                    </ul>
                </div>
            ) : (
                ''
            )}
        </>
    )
}
