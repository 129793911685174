import React, { useState, useEffect, useRef } from 'react'
import { DisplayErrors } from '../DisplayErrors'
import { supabase } from '../../lib/api'
import { useParams } from 'react-router-dom'

const STATES = {
    0: 'Inscripción',
    1: 'Lista de espera',
    2: 'Cerrado',
    3: 'Anulado',
    '-99': 'Proximamente',
}

const documents = [
    {
        text: 'DNI',
        value: 1,
    },
    {
        text: 'NIE',
        value: 2,
    },
]

export function FormUnRegisteredUserCast({ taller }) {
    const { id } = useParams()
    const clickCountRef = useRef(0)
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [sex, setSex] = useState('0')
    const [year, setYear] = useState(null)
    const [street, setStreet] = useState('1')
    const [direction, setDirection] = useState('')
    const [num, setNum] = useState('')
    const [piso, setPiso] = useState('')
    const [city, setCity] = useState('')
    const [cp, setCp] = useState('')
    const [telefon, setTelefon] = useState('')
    const [mobile, setMobile] = useState('')
    const [typeDocument, setTypeDocument] = useState(1)
    const [nif, setNif] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [knowing, setKnowing] = useState('0')
    const [errors, setErrors] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [labelButton, setLabelButton] = useState('Inscripción')

    const [errorPassword, setErrorPassword] = useState(null)
    const [errorDniRepeated, setErrorDniRepeated] = useState(null)
    const [errorInsertUser, setErrorInsertUser] = useState(null)
    const [errorInsertUserPlan, setErrorInsertUserPlan] = useState(null)

    useEffect(() => {
        if (taller !== null) {
            checkTallerState()
        }
    }, [taller])

    const handleSuccess = () => {
        setErrorInsertUser(null)
    }

    const handleErrors = () => {
        setErrorPassword(null)
        setErrorDniRepeated(null)
    }

    const checkPasswords = (password, repeatPassword) => {
        if (password === repeatPassword) {
            setErrorPassword(null)
            return false
        }

        setErrorPassword({
            type: 'error',
            value: 'Las contraseñas deben ser iguales',
        })

        handleSuccess()

        return true
    }

    const checkTallerState = () => {
        let { estat } = taller

        setDisabled(estat !== 0 && estat !== 1 ? true : false)

        setLabelButton(STATES[estat])
    }

    const checkSameNif = async (nif) => {
        let { data } = await supabase
            .from('usuarios')
            .select('id_usuario')
            .eq('dni', nif)
            .maybeSingle()

        if (data !== null) {
            setErrorDniRepeated({
                type: 'error',
                value: 'Ya existe un usuario con el mismo DNI',
            })
            setErrorInsertUser(null)
            return true
        }

        handleSuccess()

        return false
    }
    const getLastUserRecordInserted = async () => {
        let { data, error } = await supabase
            .from('usuarios')
            .select('id_usuario')
            .order('id_usuario', { ascending: false })
            .limit(1)
            .single()

        if (error) {
            console.log(error.message)
            return { id: 0 }
        }

        if (data === null) {
            return { id: 1 }
        }

        let { id_usuario } = data

        return id_usuario
    }

    const insertUser = async () => {
        let date = `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
        }-${new Date().getDate()}`

        const user = {
            id_usuario: (await getLastUserRecordInserted()) + 1,
            nom_usuari: name.toUpperCase(),
            congnoms_usuari: lastName.toUpperCase(),
            genere: sex.toUpperCase(),
            any_naixement: parseInt(year),
            via_coneixement2: knowing.toUpperCase(),
            tipus_via: parseInt(street),
            nom_via: direction.toUpperCase(),
            num_via: num,
            pis: piso,
            nom_poblacio: city.toUpperCase(),
            cp: cp,
            tlf_fixe: telefon,
            tlf_mobil: mobile,
            email: email,
            info_periodica: 0,
            observacions: '',
            condicions_servei: 0,
            data_alta: date,
            dni: nif.toUpperCase(),
            tipo_dni: typeDocument,
            password: password.toUpperCase(),
            bactualizado: 0,
        }

        const { error } = await supabase.from('usuarios').insert(user)

        if (error !== null) {
            return
        }

        setErrorInsertUser({
            type: 'success',
            value: 'Usuario creado correctamente!',
        })

        handleErrors()
    }

    const getLastRecordInserted = async () => {
        let { data, error } = await supabase
            .from('usuario_planificacion')
            .select('id')
            .order('id', { ascending: false })
            .limit(1)
            .single()

        if (error) {
            console.log(error.message)
            return { id: 0 }
        }

        if (data === null) {
            return { id: 1 }
        }

        return data
    }

    const subscribeUser = async () => {
        let data = await getLastRecordInserted()
        let { estat } = taller
        let currentYear = new Date().getFullYear()
        let currentMonth =
            new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth() + 1
        let date = `${currentYear}-${currentMonth}-${new Date().getDate()}`

        let { error } = await supabase.from('usuario_planificacion').insert({
            id: data.id + 1,
            usuari_id: (await getLastUserRecordInserted()) + 1,
            planificacion_id: id,
            fecha_inscripcion: date,
            estado: estat,
            asistencia_id: 0,
        })

        if (error) {
            return false
        }

        setErrorInsertUserPlan({
            type: 'success',
            value:
                estat === 0
                    ? 'Inscrito correctamente!'
                    : 'Apuntando a la lista de espera',
        })

        return true
    }

    const changeTallerState = async () => {
        let { data, error } = await supabase
            .from('usuario_planificacion')
            .select('usuari_id, planificacion_talleres(id_planificacio)')
            .eq('planificacion_id', id)

        let { nombre_usuaris_maxim } = taller

        if (error !== null) {
            console.log(error.message)
            return
        }

        if (nombre_usuaris_maxim > data.length) {
            return
        }

        await supabase
            .from('planificacion_talleres')
            .update({ estat: 1 })
            .eq('id_planificacio', id)
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        clickCountRef.current += 1

        if (clickCountRef.current >= 1) {
            setDisabled(true)
        }

        if (clickCountRef.current >= 2) return

        let passwordErrors = checkPasswords(password, repeatPassword) // true es que hay error
        let nifErrors = await checkSameNif(nif) // true es que hay error

        if (passwordErrors === false && nifErrors === false) {
            insertUser()

            let { estat } = taller

            if (estat !== 0 && estat !== 1) {
                return
            }

            let boolSubscribe = await subscribeUser()

            if (boolSubscribe === false) {
                return
            }

            changeTallerState()
        }
    }

    return (
        <>
            <div className="row pt-5">
                <div className="col-md-12">
                    <h5 id="taller-title3">Registro:</h5>
                </div>
            </div>
            <hr className="divider2 mb-5" />
            <form onSubmit={onSubmit}>
                <div className="row pt-4">
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Nombre:</p>
                            <input
                                placeholder="Nombre"
                                required
                                type="text"
                                id="nom"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Apellidos:</p>
                            <input
                                placeholder="Apellidos"
                                required
                                type="text"
                                id="cognoms"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Sexo:</p>
                            <select
                                id="sexe"
                                required
                                className="form-select"
                                aria-label="Default select example"
                                value={sex}
                                onChange={(e) => setSex(e.target.value)}
                            >
                                <option value="0">No especificar</option>
                                <option value="1">Hombre</option>
                                <option value="2">Mujer</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Año de nacimiento:</p>
                            <input
                                placeholder="Año de nacimiento"
                                required
                                type="number"
                                id="any"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Vía:</p>
                            <select
                                id="via"
                                required
                                className="form-select"
                                aria-label="Default select example"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                            >
                                <option value="1">C/</option>
                                <option value="2">Pl.</option>
                                <option value="3">Avda.</option>
                                <option value="4">Paseo</option>
                                <option value="5">Pasaje</option>
                                <option value="6">Rbla</option>
                                <option value="7">Ctra.</option>
                                <option value="8">Camino.</option>
                                <option value="9">Avda</option>
                                <option value="10">Trav.</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Dirección:</p>
                            <input
                                placeholder="Dirección"
                                required
                                type="text"
                                id="adreça"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={direction}
                                onChange={(e) => setDirection(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Núm.:</p>
                            <input
                                placeholder="Número"
                                required
                                type="text"
                                id="num"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={num}
                                onChange={(e) => setNum(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Piso:</p>
                            <input
                                placeholder="Piso"
                                required
                                type="text"
                                id="pis"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={piso}
                                onChange={(e) => setPiso(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Población:</p>
                            <input
                                placeholder="Población"
                                required
                                type="text"
                                id="poblacio"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">CP:</p>
                            <input
                                placeholder="Código postal"
                                required
                                type="text"
                                id="cp"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={cp}
                                onChange={(e) => setCp(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Tel.:</p>
                            <input
                                placeholder="Teléfono"
                                type="text"
                                id="telf"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={telefon}
                                onChange={(e) => setTelefon(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Móvil:</p>
                            <input
                                placeholder="Movil"
                                required
                                type="text"
                                id="mobil"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Tipo de documento</p>
                            <select
                                id="via"
                                required
                                className="form-select"
                                aria-label="Default select example"
                                value={typeDocument}
                                onChange={(e) => setTypeDocument(Number(e.target.value))}
                            >
                                {documents.map((item, index) => (
                                    <>
                                        <option key={index} value={item.value}>
                                            {item.text}
                                        </option>
                                    </>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">
                                {typeDocument === 1 ? 'DNI:' : 'NIE:'}
                            </p>
                            <input
                                placeholder={typeDocument === 1 ? 'DNI' : 'NIE'}
                                required
                                pattern={
                                    typeDocument === 1
                                        ? '[0-9]{8}[A-Z]{1}'
                                        : '[A-Z]{1}[0-9]{7}[A-Z]{1}'
                                }
                                title={
                                    typeDocument === 1
                                        ? 'Debe poner 8 números y una letra'
                                        : 'Debe poner 1 letra seguida de 7 números y una letra'
                                }
                                minlength="9"
                                maxlength="9"
                                type="text"
                                id="nif"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={nif}
                                onChange={(e) => setNif(e.target.value.toUpperCase())}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Correo electrónico:</p>
                            <input
                                placeholder="Correo electrónico"
                                required
                                type="email"
                                id="email"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p>¿Cómo nos has conocido?:</p>
                            <select
                                id="coneixement"
                                required
                                className="form-select"
                                aria-label="Default select example"
                                value={knowing}
                                onChange={(e) => setKnowing(e.target.value)}
                            >
                                <option value="0">Sin especificar</option>
                                <option value="1">Boca-Oreja</option>
                                <option value="2">Medios Comunicación</option>
                                <option value="3">Calle-Vecindario</option>
                                <option value="4">Internet</option>
                                <option value="5">Colectivos</option>
                                <option value="6">Otros</option>
                                <option value="7">Tríptico</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p className="d-none d-sm-block">Contraseña:</p>
                            <input
                                placeholder="Contraseña"
                                required
                                type="text"
                                id="contrasenya"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <p style={{ color: 'red' }} className="d-none d-sm-block">
                                Repetir contraseña:
                            </p>
                            <input
                                placeholder="Repetir contraseña"
                                required
                                type="text"
                                id="r_contrasenya"
                                className="form-control"
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={repeatPassword}
                                onChange={(e) => setRepeatPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-2 offset-md-4">
                        <button
                            id="inscripcion-user-nou-button"
                            type="submit"
                            className="btn btn-primary"
                            disabled={disabled}
                        >
                            {labelButton}
                        </button>
                    </div>
                    <DisplayErrors
                        errorPassword={errorPassword}
                        errorDniRepeated={errorDniRepeated}
                        errorInsertUser={errorInsertUser}
                        errorInsertUserPlan={errorInsertUserPlan}
                    />
                </div>
            </form>
        </>
    )
}
