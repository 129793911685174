import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ButtonApp } from './AppComponents/ButtonApp'
import { ButtonAPersonal } from './AreaPersonal/ButtonAPersonal'

export function AppBar({ language, setLanguage, logged = false }) {
    return (
        <>
            <div className="container-md">
                <nav className="navbar navbar-light">
                    <div className="container-fluid">
                        <a className="navbar-brand">
                            <img
                                className="amb"
                                src="../images/AMB_logo_Guia.png"
                                alt=""
                                style={{ width: 'auto', height: '40px' }}
                            />
                        </a>
                        <div
                            style={{
                                justifyContent: 'space-between',
                            }}
                        >
                            {logged ? null : (
                                <span
                                    style={{
                                        paddingRight: 5,
                                        marginBottom: 100,
                                    }}
                                >
                                    <ButtonAPersonal language={language} />
                                </span>
                            )}
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">
                                    Selecciona un idioma
                                </InputLabel>
                                <Select
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                    placeholder="Selecciona un idioma"
                                >
                                    <MenuItem value={'castellano'}>
                                        {language === 'castellano'
                                            ? 'castellano'
                                            : 'castellà'}
                                    </MenuItem>
                                    <MenuItem value={'catalan'}>
                                        {language === 'catalan' ? 'catalán' : 'català'}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </nav>
                <hr className="divider"></hr>
            </div>
        </>
    )
}
