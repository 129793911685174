import React from 'react'

export function DisplayErrorAsistenciaRegisteredUsers({ errorAsistencia }) {
    return (
        <>
            {errorAsistencia !== null ? (
                <div class="alert alert-secondary" role="alert">
                    <ul>
                        {errorAsistencia !== null ? (
                            <li
                                style={{
                                    paddingTop: 10,
                                    color: errorAsistencia.type === 'error' ? 'red' : '#66bb6a',
                                    fontWeight: 'bold',
                                }}
                            >
                                {errorAsistencia.value}
                            </li>
                        ) : (
                            ''
                        )}
                    </ul>
                </div>
            ) : (
                ''
            )}
        </>
    )
}
